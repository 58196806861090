import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Breadcrumb, Col, Modal, Row } from 'antd';
import { QRCode } from 'antd-v5';

import { SettingsManager } from '@classes/settingsManager';
import { faHouse, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TAB_ROUTES } from '@organisms/workers/common/constants';

import { getUserData } from '../common/redux/selectors';

// import { QRCode } from 'antd';

export const Header = () => {
    const [isQrModalOpen, setIsQrModalOpen] = useState(false);

    const navigate = useNavigate();
    const creds = SettingsManager.getConnectionCredentials();

    const userData = useSelector(getUserData);

    return (
        <>
            <Row
                className="breadcrumb-header"
                style={{ height: 'auto', justifyContent: 'space-between' }}
            >
                <Col className="header-location">
                    <span>Профиль пользователя</span>
                </Col>
                <Col className="header-search" onClick={() => setIsQrModalOpen(true)}>
                    <QRCode size={50} value="https://google.com" />
                </Col>
            </Row>
            <Row className="breadcrumbs-controls">
                <Col className="breadcrumbs">
                    <Breadcrumb>
                        <Breadcrumb.Item onClick={() => navigate(`/lk/worker/crm/${creds?.crmID}`)}>
                            <FontAwesomeIcon icon={faHouse} />
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            onClick={() =>
                                navigate(
                                    `/lk/worker/crm/${creds?.crmID}/workers/tabs/${TAB_ROUTES.WORKING_NOW}`
                                )
                            }
                        >
                            <FontAwesomeIcon icon={faUserTie} />
                            <span className="crumb-name">Сотрудники</span>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>ID: {userData?.id}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>

            <Modal open={isQrModalOpen} onCancel={() => setIsQrModalOpen(false)} centered footer={null}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <QRCode size={300} value="https://google.com" />
                </div>
            </Modal>
        </>
    );
};
