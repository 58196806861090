import React, { SyntheticEvent, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Avatar, Button, Col, Divider, Input, List, Modal, Row, Tabs } from 'antd';
import { observer } from 'mobx-react';
import { CustomerParams } from 'types/getParams';
import { TMetadata } from 'types/metadata';
import { TUser } from 'types/user/user';

import { UserListResp } from '@api/responseModels/user/userListResponse';
import { CRMAPIManager } from '@classes/crmApiManager';
import { messageService } from '@classes/messageService';
import { SettingsManager } from '@classes/settingsManager';
import { GlobalConstants } from '@constants/global';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFetchDataList } from '@hooks/useFetchDataList';
import { store as pStore } from '@molecules/paginationControls/pageSizeStore';
import { FilterStore } from '@organisms/customersFilter/filterStore';
import { CreateWorkerDialog } from '@organisms/dialogs/createWorkerDialog';

import { debounce } from '../../../utils/functions';

const { Search } = Input;
const filterStore = new FilterStore();
const filter = filterStore.filter;

function filterCustomerList(customerList: TUser[]): TUser[] {
    return customerList.filter((user) => user?.customer_profile);
}

type tProps = {
    isModalOpen: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    currentOrderCustomer: TUser | null;
    setCurrentOrderCustomer: React.Dispatch<React.SetStateAction<TUser>>;
};

const OrderCustomerPicker = observer(
    ({ isModalOpen, setIsModalOpen, setCurrentOrderCustomer }: tProps) => {
        const [isLoading, setIsLoading] = useState<boolean>(false);
        const [currentPage, setCurrentPage] = useState<number>(1);
        const [customerList, setCustomerList] = useState<Array<TUser>>([]);
        const [currentMeta, setCurrentMeta] = useState<TMetadata>(null);
        const [selectedOrderCustomer, setSelectedOrderCustomer] = useState<TUser>(null);

        const filteredListCustomer = filterCustomerList(customerList);

        const [_workerList, setWorkerList] = useState<Array<TUser>>([]);
        const [inviteWorkerOpen, setInviteWorkerOpen] = useState<boolean>(false);

        async function getCustomerList(page: number = currentPage) {
            if (isLoading) return;
            setIsLoading(true);
            try {
                const creds = SettingsManager.getConnectionCredentials();

                const customerList = await CRMAPIManager.request<UserListResp>(async (api) => {
                    const params: CustomerParams = {
                        crm_id: creds.crmID,
                        sort_by: filter.sortBy,
                        sort_direction: filter.sortDirection,
                        filters: {
                            created_at: [...filter.createdDates],
                            updated_at: [...filter.updatedDates],
                            deleted: filter.deleted,
                            profile: 'customer',
                        },
                        query: filter.query,
                        page,
                        per_page: pStore.pS,
                    };
                    Object.keys(params.filters).filter(
                        (key) => params.filters[key] === null && delete params.filters[key]
                    );
                    return await api.getUserList(params);
                });
                if (customerList.errorMessages) {
                    setCustomerList([]);
                    throw customerList.errorMessages;
                }
                setCustomerList((wl) => [...wl, ...customerList.data.data]);
                setCurrentMeta(customerList.data.meta);
            } catch (err) {
                messageService.sendError(err.message);
            }
            setIsLoading(false);
        }

        function handleChangePage() {
            getCustomerList(currentPage + 1).then(() => {
                setCurrentPage((prev) => prev + 1);
            });
        }

        const handlePickerOK = () => {
            setCurrentOrderCustomer(selectedOrderCustomer);
            setIsModalOpen(false);
        };

        const handlePickerCancel = () => {
            setIsModalOpen(false);
        };

        const handleSearchChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
            setCustomerList([]);
            setCurrentPage(1);
            filterStore.changeQuery(e.target.value);
        }, GlobalConstants.SearchTimeout);

        function handleSelectButtonClick(e: SyntheticEvent, customer: TUser) {
            e.stopPropagation();
            selectedOrderCustomer?.id == customer.customer_profile.id
                ? setSelectedOrderCustomer(null)
                : setSelectedOrderCustomer(customer);
        }

        function beforeMountCustomerList() {
            getCustomerList();
        }

        useEffect(() => {
            beforeMountCustomerList();
        }, [filter.query]);

        const { getDataList, isLoading: _isCustomerListLoading } = useFetchDataList({
            filter,
            profile: 'customer',
            setCurrentMeta,
            setDataList: setWorkerList,
            getFnKey: 'getUserList',
        });

        useEffect(() => {
            if (currentMeta?.total == null || currentMeta?.total == 0) return;
            const newPage = Math.ceil(currentMeta.from / pStore.pS);
            getCustomerList(newPage).then(() => {
                setCurrentPage(newPage);
            });

            getDataList(newPage).then(() => {
                setCurrentPage(newPage);
            });
        }, [pStore.pS]);

        return (
            <>
                <CreateWorkerDialog
                    isModalOpen={inviteWorkerOpen}
                    setIsModalOpen={setInviteWorkerOpen}
                    isCustomer={true}
                    getCustomerList={getDataList}
                />
                <Modal
                    zIndex={899}
                    className="product-category-picker"
                    title={
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <span>Выберите клиента</span>
                            <Button
                                style={{ marginRight: '20px' }}
                                type="primary"
                                onClick={() => setInviteWorkerOpen(true)}
                            >
                                Новый клиент
                            </Button>
                        </div>
                    }
                    open={isModalOpen}
                    onOk={handlePickerOK}
                    onCancel={handlePickerCancel}
                    cancelText="Отмена"
                    wrapClassName="sticky-modal"
                >
                    <Row>
                        <Col span={24}>
                            <Search
                                allowClear
                                className="header-search-input"
                                onChange={handleSearchChange}
                            />
                        </Col>
                    </Row>
                    <Tabs
                        defaultActiveKey="1"
                        centered
                        items={[
                            {
                                key: '1',
                                label: 'Список клиентов',
                                children: (
                                    <>
                                        <InfiniteScroll
                                            dataLength={customerList.length}
                                            next={handleChangePage}
                                            hasMore={customerList.length < currentMeta?.total}
                                            loader={<Divider plain>Загрузка...</Divider>}
                                            endMessage={
                                                <Divider plain>Все клиенты загружены :^)</Divider>
                                            }
                                            // height={'calc(90vh - 220px)'}
                                            height={
                                                window.screen.height < 950
                                                    ? 'calc(90vh - 220px)'
                                                    : 'calc(75vh - 220px)'
                                            }
                                        >
                                            <List
                                                className="customer-card-list"
                                                dataSource={filteredListCustomer}
                                                itemLayout="horizontal"
                                                renderItem={(item) => (
                                                    <List.Item
                                                        className="customer-card"
                                                        key={item.id}
                                                        onClick={(e) =>
                                                            handleSelectButtonClick(e, item)
                                                        }
                                                    >
                                                        <Row style={{ width: '100%' }}>
                                                            <Col span={3}>
                                                                <Avatar
                                                                    className="avatar"
                                                                    src={`${
                                                                        GlobalConstants.BaseUrlForImg
                                                                    }${item?.picture as string}`}
                                                                />
                                                            </Col>
                                                            <Col span={18}>
                                                                <Row>
                                                                    <Col>
                                                                        <h3 className="title">
                                                                            {item.name +
                                                                                ' ' +
                                                                                item.surname}
                                                                        </h3>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col span={3} className="button-select">
                                                                <Button
                                                                    icon={
                                                                        <FontAwesomeIcon
                                                                            icon={faCheck}
                                                                        />
                                                                    }
                                                                    shape="circle"
                                                                    type={
                                                                        item?.customer_profile
                                                                            ?.id ===
                                                                        (
                                                                            selectedOrderCustomer as TUser
                                                                        )?.customer_profile?.id
                                                                            ? 'primary'
                                                                            : 'default'
                                                                    }
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </List.Item>
                                                )}
                                            />
                                        </InfiniteScroll>
                                    </>
                                ),
                            },
                            {
                                key: '2',
                                label: 'Список звонков',
                                children: 'Список звонков пуст',
                                disabled: true,
                            },
                        ]}
                    />
                </Modal>
            </>
        );
    }
);

export { OrderCustomerPicker };
