import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Col, Row } from 'antd';
import { TUser } from 'types/user/user';

import { FileResp } from '@api/responseModels/file/fileResponse';
import { InvitationResp } from '@api/responseModels/invitation/invitationResponse';
import { TransactionResp } from '@api/responseModels/transactions/transactionResponse';
import { UserResp } from '@api/responseModels/user/userResponse';
import { WorkerProfileResp } from '@api/responseModels/worker/workerProfileResponse';
import { Loader } from '@atoms/loader';
import { CRMAPIManager } from '@classes/crmApiManager'; 
import { messageService } from '@classes/messageService';
import { SettingsManager } from '@classes/settingsManager';
import { GlobalConstants } from '@constants/global';
import { faCheck, faEdit, faUserTie, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HeaderBreadcrumbProfiles } from '@molecules/breadcrumbs/profilesBreadcrumbs/HeaderProfilesBreadcrumb';
import { FunctionalMenu } from '@molecules/functionalMenu';
import { ProfileInfoCard } from '@molecules/profileInfoCard';
import { LastIdStore } from '@pages/lastIdStore';
import { rootStore } from '@store/rootStore/instanse';

import { Common } from '../../../classes/commonMethods';
import { ImgUploader } from '../imgUploader/imgUploader';
import { TAB_ROUTES } from './common/constants';
import { ProfileBalance } from './components/profileBalance';

const WorkerProfile = (): JSX.Element => {
    const userId = LastIdStore.lastUserId;
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [user, setUser] = useState<TUser>(null);
    const [currentUser, setCurrentUser] = useState(null);
    const isForInvitation = useRef<boolean>(null);
    const [editing, setEditing] = useState<boolean>(false);
    const numberValid = useRef(false);
    const [numberValue, setNumberValue] = useState<string>('');
    const [isUserProfile, setUserProfile] = useState<boolean>(false);
    /// Навигация
    const currentID = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    const creds = SettingsManager.getConnectionCredentials();
    const phoneMask = /^[0-9]{10}$/;
    const emailMask =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;

    const profileBalance = Common.formatCurrency(
        user?.worker_profile?.balance ?? user?.customer_profile?.balance ?? 0
    );

    async function getUser() {
        setIsLoading(true);
        try {
            const creds = SettingsManager.getConnectionCredentials();
            const tuser = await CRMAPIManager.request<UserResp>(async (api) => {
                if (creds.userId === userId || location.pathname.includes('profile')) {
                    setUserProfile(true);
                } 
                return await api.getUser(
                    userId || currentID.current || creds.userId,
                    creds.crmID
                );
            });
            if (tuser.errorMessages) throw tuser.errorMessages;
            setUser(tuser.data.data);
            setCurrentUser(creds.userId);
            setNumberValue(tuser.data.data.phone);
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        setIsLoading(false);
    }

    async function handleEditing() {
        try {
            if (location.pathname.includes('profile')) navigate(`/lk/worker/profile/edit`);
            else {
                navigate(
                    `/lk/worker/crm/${creds.crmID}/workers/${user.id || currentID.current}/edit`
                );
            }

            setEditing(true);
            numberValid.current = phoneMask.test(numberValue);
            const tuser = await CRMAPIManager.request<UserResp>(async (api) => {
                if (creds.userId === userId || location.pathname.includes('profile')) {
                    setUserProfile(true);
                } 
                return await api.getUser(userId || currentID.current, creds.crmID);
            });
            if (tuser.errorMessages) throw tuser.errorMessages;
            setUser(tuser.data.data);
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
    }

    function validateWorker(): boolean {
        if (!user?.name || user?.name == '') {
            messageService.sendError('Имя не может быть пустым');
            return false;
        }
        if (!user?.surname || user?.surname == '') {
            messageService.sendError('Фамилия не может быть пустой');
            return false;
        }
        if (!user?.email || user?.email == '') {
            messageService.sendError('Email не может быть пустой');
            return false;
        }
        if (!user?.email || !emailMask.test(user?.email)) {
            messageService.sendError('Введен некорректный Email');
            return false;
        }
        if (!numberValid.current && !isForInvitation.current) {
            messageService.sendError('Номер некорректен');
            return false;
        }
        return true;
    }

    function checkCurrentUrl() {
        const urlArr = window.location.pathname.split('/');

        if (urlArr[urlArr.length - 1] == 'edit') {
            currentID.current = Number(urlArr[urlArr.length - 2]);
        } else {
            currentID.current = Number(urlArr[urlArr.length - 1]);
        }
        if (creds.userId === currentID.current) setUserProfile(true);
        if (location.pathname.includes('edit')) {
            isForInvitation.current = false;
            setEditing(true);
        } else if (location.pathname.includes('create')) {
            isForInvitation.current = true;
            setEditing(true);
        }
    }

    async function handleSaveEditing() {
        numberValid.current = phoneMask.test(numberValue);
        if (!validateWorker()) {
            return;
        }
        setIsLoading(true);

        try {
            user.picture =
                user.picture === '/images/users/no_image_customer.svg' ||
                user.picture === '/images/users/no_image_worker.svg'
                    ? null
                    : user.picture;

            if (isForInvitation.current) {
                const inv = await CRMAPIManager.request<InvitationResp>(async (api) => {
                    return await api.inviteNotRegisterUser(creds.crmID, user, 'worker');
                });

                if (inv.errorMessages) throw inv.errorMessages;

                // загружаем картинку
                if (user.picture && typeof user.picture == 'object') {
                    try {
                        const picture = await CRMAPIManager.request<FileResp>(async (api) => {
                            return await api.saveFile({
                                crm_id: creds.crmID,
                                model: 'user',
                                entity_id: inv.data.data.receive_user_id,
                                picture: user.picture,
                            });
                        });
                    } catch (errors) {
                        messageService.sendError(errors[0][0]);
                    }
                }

                localStorage.removeItem('newPhone');
                localStorage.setItem('invitationIsSent', 'true');

                setEditing(false);

                navigate(`/lk/worker/crm/${creds.crmID}/workers/tabs/${TAB_ROUTES.WORKING_NOW}`);
            } else {
                if (isUserProfile) {
                    const tuser = await CRMAPIManager.request<UserResp>(async (api) => {
                        return await api.updateUser(creds.crmID, user);
                    });

                    if (tuser.errorMessages) throw tuser.errorMessages;

                    setUser(tuser.data.data);

                    console.log(creds);

                    if (!user.picture && creds?.crmID) {
                        //удаляем картинку
                        try {
                            const picture = await CRMAPIManager.request<FileResp>(async (api) => {
                                return await api.removeFile({
                                    crm_id: creds.crmID,
                                    model: 'user',
                                    entity_id: tuser.data.data.id,
                                });
                            });

                            setUser((prevState) => {
                                return { ...prevState, picture: picture.data.data };
                            });
                        } catch (err) {
                            messageService.sendError(err[0][0]);
                        }
                    }

                    // загружаем картинку
                    if (user.picture && typeof user.picture == 'object' && creds?.crmID) {
                        try {
                            const picture = await CRMAPIManager.request<FileResp>(async (api) => {
                                return await api.saveFile({
                                    crm_id: creds.crmID,
                                    model: 'user',
                                    entity_id: tuser.data.data.id,
                                    picture: user.picture,
                                });
                            });

                            setUser((prevState) => {
                                return {
                                    ...prevState,
                                    picture: picture.data.data,
                                };
                            });
                        } catch (errors) {
                            messageService.sendError(errors[0][0]);
                        }
                    }
                }

                if (creds?.crmID) {
                    const tworkerProfile = await CRMAPIManager.request<WorkerProfileResp>(
                        async (api) => {
                            return await api.updateWorkerProfile(creds.crmID, user.worker_profile);
                        }
                    );

                    if (tworkerProfile.errorMessages) throw tworkerProfile.errorMessages;

                    if (user.id === rootStore.currentUserStore.getUser.id) {
                        await rootStore.currentUserStore.fetchCurrentUser();
                    }

                    setUser((prevState) => ({
                        ...prevState,
                        worker_profile: tworkerProfile.data.data,
                    }));

                    if (creds?.userId == tworkerProfile.data.data.user_id) {
                        SettingsManager.updateConnectionCredentials({
                            workerProfileId: tworkerProfile.data.data.id,
                        });
                    }

                    setEditing(false);
                    navigate(
                        `/lk/worker/crm/${creds.crmID}/workers/${user.id || currentID.current}`
                    );
                } else {
                    navigate('/lk/worker/profile');
                    setEditing(false);
                }
            }
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        setIsLoading(false);
    }

    async function handleAbortEditing() {
        if (isForInvitation.current) {
            localStorage.removeItem('newPhone');
            navigate(`/lk/worker/crm/${creds.crmID}/workers/tabs/${TAB_ROUTES.WORKING_NOW}`);
        } else {
            setEditing(false);
            navigate(
                creds?.crmID
                    ? `/lk/worker/crm/${creds.crmID}/workers/${user.id || currentID.current}`
                    : '/lk/worker/profile'
            );
            await getUser();
        }
    }

    function handleImageSelect(file: File) {
        setUser((prevState) => ({ ...prevState, picture: file }));
    }

    function handleImageDelete() {
        setUser((prevState) => ({ ...prevState, picture: null }));
    }

    async function checkWorkerBelongToCrm() {
        try {
            const urlArr = window.location.pathname.split('/');
            if (urlArr[urlArr.length - 1] == 'edit') {
                currentID.current = Number(urlArr[urlArr.length - 2]);
            } else {
                currentID.current = Number(urlArr[urlArr.length - 1]);
            }

            const tuser = await CRMAPIManager.request<UserResp>(async (api) => {
                if (creds.userId === userId || location.pathname.includes('profile')) {
                    setUserProfile(true);
                } 
                return await api.getUser(
                    userId || currentID.current || creds.userId,
                    creds.crmID
                );
                
            });

            if (!isNaN(currentID.current) && tuser.statusCode == 404) {
                navigate(`/lk/worker/crm/${creds.crmID}/workers/tabs/${TAB_ROUTES.WORKING_NOW}`);
            }
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
    }

    function beforeMountWorker() {
        checkCurrentUrl();
        Common.checkUserBelongToCrm(GlobalConstants.CrmOrderinUrl).then((res) => {
            if (!res && !location.pathname.includes('profile'))
                navigate(`/lk/worker/crm/${creds.crmID}`);
        });
        if (!isNaN(currentID.current || location.pathname.includes('profile'))) {
            checkWorkerBelongToCrm().then(() => {
                !isForInvitation.current
                    ? getUser()
                    : messageService.sendInfo('Пользователь не найден, пожалуйста заполните поля');
            });
        }
    }

    async function prepareTransaction({ transactionType, transactionAmount }) {
        setIsLoading(true);
        try {
            const transaction = await CRMAPIManager.request<TransactionResp>(async (api) => {
                return await api.createTransaction({
                    crm_id: creds?.crmID,
                    receiver_type: 'worker',
                    receiver_id:
                        transactionType == 'Пополнение'
                            ? user.worker_profile.id
                            : creds?.workerProfileId,
                    sender_type: 'worker',
                    sender_id:
                        transactionType == 'Пополнение'
                            ? creds?.workerProfileId
                            : user.worker_profile.id,
                    sum: transactionAmount,
                    payment_method_id: 1,
                    transaction_type_id: 1,
                });
            });
            if (transaction.errorMessages) throw transaction.errorMessages;

            messageService.sendSuccess(
                `Перевод ${user.name} ${user.surname} на сумму ${transactionAmount} руб.`
            );
            await getUser();
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        beforeMountWorker();
    }, [userId]);

    return (
        <div id="app-worker-profile">
            {isLoading && <Loader />}

            <FunctionalMenu
                items={
                    editing
                        ? [
                              {
                                  key: 'save',
                                  label: 'Сохранить',
                                  icon: <FontAwesomeIcon icon={faCheck} />,
                                  onClick: async () => {
                                      await handleSaveEditing();
                                  },
                              },
                              {
                                  key: 'abort',
                                  label: 'Отменить',
                                  icon: <FontAwesomeIcon icon={faXmark} />,
                                  onClick: handleAbortEditing,
                              },
                          ]
                        : [
                              {
                                  key: 'edit',
                                  label: 'Редактировать',
                                  icon: <FontAwesomeIcon icon={faEdit} />,
                                  onClick: handleEditing,
                              },
                          ]
                }
                dropdownItems={[]}
            />
            <HeaderBreadcrumbProfiles
                dataIcon={faUserTie}
                dataId={user?.id}
                dataTitle={'Сотрудники'}
                title={'Профиль сотрудника'}
                route={`/lk/worker/crm/${creds?.crmID}/workers/tabs/${TAB_ROUTES.WORKING_NOW}`}
                isForInvitation={isForInvitation.current}
                dataName={'Новый пользователь'}
                isSpecialty={false}
            />
            <div className="user-profile">
                <Row className="upper-info" justify={'center'}>
                    <Col className="avatar outer-box-for-image">
                        <ImgUploader
                            photoSrc={user?.picture as string}
                            onImageSelect={handleImageSelect}
                            isEdit={editing && user?.id == currentUser}
                            onImageDelete={handleImageDelete}
                        />
                    </Col>
                    {!isForInvitation.current && (
                        <ProfileBalance
                            balance={profileBalance}
                            userWorkerProfileId={user?.worker_profile?.id}
                            prepareTransaction={prepareTransaction}
                        />
                    )}
                </Row>
                <ProfileInfoCard
                    areAllFieldsEditable={isForInvitation.current}
                    editing={editing}
                    isUserProfile={isUserProfile}
                    user={
                        isForInvitation.current
                            ? {
                                  ...user,
                                  phone: localStorage.getItem('newPhone'),
                              }
                            : user
                    }
                    setUser={setUser}
                    setNumberValue={setNumberValue}
                    phoneNumberValue={
                        isForInvitation.current ? localStorage.getItem('newPhone') : numberValue
                    }
                    // isOwnerCRMUser={isOwnerCRMUser}
                    isCustomer={false}
                />
            </div>
        </div>
    );
};

export { WorkerProfile };
