import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useReactive } from 'ahooks';
import { Button, Row, Space } from 'antd';
import { filter, find, isEmpty, map } from 'lodash';
import { TWorksheet } from 'types/orderFields/orderFields';
import { TWorksheetFields } from 'types/worksheets/worksheetFields';

import { WorksheetFieldsResp } from '@api/responseModels/worksheet/worksheetFieldsResponse';
import { CRMAPIManager } from '@classes/crmApiManager';
import { SettingsManager } from '@classes/settingsManager';
import RenderIf from '@common/RenderIf';
import { faChevronDown, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFilteredWorksheetFields } from '@organisms/settings/common/helpers/getFilteredWorksheetFields';
import { LOADING_IDS } from '@utils/redux/Loadings/loadingIds';
import { toggleLoading } from '@utils/redux/Loadings/loadingSlice';

import { setOrderProperty } from '../../common/redux/orderSlice';
import {
    getImmutableRequiredOrderFields,
    getOrderFields,
    getSelectedWorksheet,
} from '../../common/redux/selectors';

import './worksheetButtons.scss';

export const WorksheetButtons = ({ data }) => {
    const selectedWorksheetId = localStorage.getItem('selectedWorksheetId');
    const state = useReactive({
        worksheets: [],
        selectedWorksheetId: selectedWorksheetId ?? 1,
        showAllWorksheets: false,
    });

    const creds = SettingsManager.getConnectionCredentials();
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const selectedWorksheet: TWorksheetFields = useSelector(getSelectedWorksheet);
    const orderFields = useSelector(getOrderFields);
    const immutableRequiredOrderFields = useSelector(getImmutableRequiredOrderFields);

    useEffect(() => {
        if (isEmpty(data)) return;
        state.worksheets = data;
    }, [data]);

    const fetchWorksheetFields = async (id: number) => {
        try {
            dispatch(toggleLoading({ isLoading: true, ids: [LOADING_IDS.WORKSHEETS] }));
            const resp = await CRMAPIManager.request<WorksheetFieldsResp>(async (api) => {
                return await api.getWorksheetFields(id, creds.crmID);
            });
            dispatch(toggleLoading({ isLoading: false, ids: [LOADING_IDS.WORKSHEETS] }));
            if (resp.errorMessages) throw resp.errorMessages;

            const worksheetFieldsData = getFilteredWorksheetFields(resp.data.data);

            dispatch(setOrderProperty({ key: 'selectedWorksheet', value: worksheetFieldsData }));
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (isEmpty(selectedWorksheet)) return;

        const mappedOrderFields = map(selectedWorksheet.order_fields, (item) => {
            const match = find(orderFields, { id: item.id });
            return {
                ...item,
                value: match ? match.pivot.value : '',
            };
        });

        const fieldsWithoutDuplicates = filter(mappedOrderFields, (reqField) => {
            return !find(immutableRequiredOrderFields, (field) =>
                field?.isOrderObject ? false : field.id === reqField.id && !reqField.value
            );
        });

        dispatch(
            setOrderProperty({
                key: 'immutableOrderFields',
                value: fieldsWithoutDuplicates,
            })
        );
        dispatch(
            setOrderProperty({
                key: 'mutableOrderFields',
                value: fieldsWithoutDuplicates,
            })
        );
    }, [orderFields, selectedWorksheet]);

    const moveActiveWorksheet = (worksheet): void => {
        const indexToMove = state.worksheets.indexOf(worksheet);
        if (indexToMove !== -1) {
            state.worksheets.splice(indexToMove, 1);
            state.worksheets.unshift(worksheet);
        }
    };

    const handleSelectWorksheet = (id: number) => {
        state.selectedWorksheetId = id;
        localStorage.setItem('selectedWorksheetId', id.toString());
        const worksheet = state.worksheets.find((worksheet: TWorksheet) => worksheet.id === id);

        moveActiveWorksheet(worksheet);
        state.showAllWorksheets = false;
        fetchWorksheetFields(id);
    };

    useEffect(() => {
        if (isEmpty(state.worksheets)) return;

        if (!selectedWorksheetId) localStorage.setItem('selectedWorksheetId', '1');
        state.selectedWorksheetId = +selectedWorksheetId || 1;

        const worksheet = state.worksheets.find(
            (worksheet: TWorksheet) => worksheet.id === +selectedWorksheetId || 1
        );
        moveActiveWorksheet(worksheet);
        fetchWorksheetFields(+selectedWorksheetId || 1);
    }, [data]);

    return (
        <>
            <div className="btns-wrapper">
                {map(state.worksheets, (worksheet: TWorksheet, index) => {
                    if (index < 2) {
                        return (
                            <Button
                                key={worksheet.id}
                                className={
                                    worksheet.id === state.selectedWorksheetId
                                        ? 'worksheet-button active'
                                        : 'worksheet-button'
                                }
                                onClick={() => {
                                    handleSelectWorksheet(worksheet.id);
                                    state.selectedWorksheetId = worksheet?.id;
                                }}
                            >
                                <div className="btn-text">{worksheet?.name}</div>
                            </Button>
                        );
                    }
                    return undefined;
                })}
                <Button
                    className={
                        state.showAllWorksheets
                            ? 'worksheet-button main-btn'
                            : 'worksheet-button active main-btn'
                    }
                    onClick={() => (state.showAllWorksheets = !state.showAllWorksheets)}
                >
                    <Space>
                        <FontAwesomeIcon
                            className={state.showAllWorksheets ? 'arrow-active' : 'arrow-inactive'}
                            icon={faChevronDown}
                        />
                        Раб. листы
                    </Space>
                </Button>
            </div>
            {/* Список кнопок с рабочими листами (временное решение) */}
            <RenderIf condition={state.showAllWorksheets}>
                <div className={'worksheets-btns-container'}>
                    <Row className={'worksheets-btns'}>
                        {state.worksheets.map((worksheet: TWorksheet, index) => {
                            if (index > 1) {
                                return (
                                    <Button
                                        key={worksheet.id}
                                        className={
                                            worksheet.id === state.selectedWorksheetId
                                                ? 'worksheet-button active'
                                                : 'worksheet-button'
                                        }
                                        onClick={() => {
                                            handleSelectWorksheet(worksheet.id);
                                            state.selectedWorksheetId = worksheet.id;
                                        }}
                                    >
                                        <div className="btn-text">{worksheet.name}</div>
                                    </Button>
                                );
                            }
                            return undefined;
                        })}
                    </Row>
                    <Row
                        onClick={() => {
                            navigate(
                                `/lk/worker/crm/${creds.crmID}/settings/orders/worksheets/${state.selectedWorksheetId}`
                            );
                        }}
                        className={'button-container'}
                    >
                        <FontAwesomeIcon size="2xl" icon={faPenToSquare} />
                    </Row>
                </div>
            </RenderIf>
        </>
    );
};
