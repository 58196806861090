import { Button, Space, Typography } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';

import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    name: string;
    icon: any;
    background: string;
    onClick: () => void;
    iconSize?: SizeProp;
    buttonSize?: SizeType;
}

export const MessengerButton: React.FC<Props> = ({
    background = '#ffff',
    icon,
    onClick,
    name,
    iconSize = 'lg',
    buttonSize = 'large',
}) => {
    return (
        <>
            <Space
                onClick={onClick}
                direction="vertical"
                align="center"
                size={'small'}
                style={{ cursor: 'pointer' }}
            >
                <Button
                    icon={<FontAwesomeIcon icon={icon} color="#ffff" size={iconSize} />}
                    color={'blue'}
                    shape="circle"
                    size={buttonSize}
                    style={{
                        background,
                        color: 'unset',
                    }}
                />
                <Typography.Text>{name}</Typography.Text>
            </Space>
        </>
    );
};
