import { rootStore } from '@store/rootStore/instanse';

import { TOPIC_CATEGORIES } from './components/commentTopicsPicker/common/constants';

export const authorName = 'Андрей Д.';
export const authorPosition = 'Менеджер';
export const repliedToName = 'Андрей Ж.';
export const repliedToPosition = 'Администратор';

export const TOPIC = {
    product: 'Продукт: ',
    service: 'Услуга: ',
    custom: '',
};

const author = rootStore.currentUserStore.getUser;

export const NO_TOPIC = {
    id: 'no_topic',
    name: 'Без темы',
    price: null,
    quantity: null,
    total: null,
    category: TOPIC_CATEGORIES.CUSTOM,
};

export const initialComment = {
    id: 1,
    author: {
        ...author,
        position: authorPosition,
    },
    repliedTo: [],
    repliedComment: null,
    date: 'Вчера',
    time: '10:31',
    topic: null,
    body: {
        text: 'замена шаровой опоры Лексус RX 300 замена шаровой опоры Лексус RX 300 замена шаровой опоры Лексус RX 300 замена шаровой опоры Лексус RX 300',
        files: [],
    },
};

export const replyCommentInitialData = {
    recipient: null,
    comment: null,
    topic: null,
};

export const FILTER_KEYS = {
    FILE: 'files',
    MEMBER: 'members',
    TOPIC: 'topics',
    DATE: 'date',
    SEARCH: 'search',
};

export const acceptedFormats = '.jpg, .jpeg, .gif, .web, .png, .pdf, .xlsx, .xls, .doc, .docx';

export const FILES_GROUP = {
    ALL: 'Все',
    WORD: 'Word',
    EXCEL: 'Excel',
    VIDEO: 'Видео',
    AUDIO: 'Голосовые',
    IMAGE: 'Фото',
    PDF: 'Pdf',
};

export const GROUPED_FILE_FORMATS = {
    // Изображения
    jpg: FILES_GROUP.IMAGE,
    jpeg: FILES_GROUP.IMAGE,
    gif: FILES_GROUP.IMAGE,
    web: FILES_GROUP.IMAGE,
    png: FILES_GROUP.IMAGE,
    apng: FILES_GROUP.IMAGE,
    avif: FILES_GROUP.IMAGE,

    // Word
    doc: FILES_GROUP.WORD,
    docx: FILES_GROUP.WORD,

    // Excel
    xls: FILES_GROUP.EXCEL,
    xlsx: FILES_GROUP.EXCEL,

    // Pdf
    pdf: FILES_GROUP.PDF,

    // Audio
    audio: FILES_GROUP.AUDIO,
};

export const FORMATS = {
    IMAGE: 'IMAGE',
    PDF: 'PDF',
    WORD: 'WORD',
    EXCEL: 'EXCEL',
    UNSUPPORTED: 'UNSUPPORTED',
};

export const AUDIO = 'audio';

export const EXTENSIONS_DATA = {
    // ИЗОБРАЖЕНИЯ
    jpeg: FORMATS.IMAGE,
    jpg: FORMATS.IMAGE,
    png: FORMATS.IMAGE,
    gif: FORMATS.IMAGE,
    svg: FORMATS.IMAGE,
    avif: FORMATS.IMAGE,
    apng: FORMATS.IMAGE,
    webp: FORMATS.IMAGE,
    heic: FORMATS.IMAGE,

    // PDF
    pdf: FORMATS.PDF,

    // WORD
    doc: FORMATS.WORD,
    docx: FORMATS.WORD,

    // EXCELL
    xls: FORMATS.EXCEL,
    xlsx: FORMATS.EXCEL,

    // БЕЗ ПОДДЕРЖКИ
    UNSUPPORTED: FORMATS.UNSUPPORTED,
};
