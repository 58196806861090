import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useCreation, useReactive } from 'ahooks';
import { Button, Dropdown, Input, MenuProps, message } from 'antd';
import { filter, find, map } from 'lodash';
import { TOrderObjectsCreate } from 'types/orderObjects/orderObjectsCreate';
import { TOrderFieldExtended } from 'types/worksheets/worksheetFields';

import { OrderObjectResp } from '@api/responseModels/orderObjects/orderObjectResponse';
import { OrderObjectsResp } from '@api/responseModels/orderObjects/orderObjectsResponse';
import { CRMAPIManager } from '@classes/crmApiManager';
import { SettingsManager } from '@classes/settingsManager';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ORDER_OBJECT } from '@organisms/orders/profile/common/constants';
import {
    setOrderObject,
    setOrderProperty,
} from '@organisms/orders/profile/common/redux/orderSlice';
import {
    getMutableOrderFields,
    getMutableRequiredOrderFields,
    getOrderCustomerProfileId,
    getOrderObjects,
} from '@organisms/orders/profile/common/redux/selectors';
import { LOADING_IDS } from '@utils/redux/Loadings/loadingIds';
import { toggleLoading } from '@utils/redux/Loadings/loadingSlice';

import { EditTemplateProps } from '../../types';

import './editTemplate.scss';

export const EditTemplate = ({
    onChange,
    value,
    hideOrderObject,
    isOrderCreation,
}: EditTemplateProps) => {
    const state = useReactive({
        isTemplateExists: false,
        templateValue: '',
    });
    const creds = SettingsManager.getConnectionCredentials();
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const mutableOrderFields: TOrderFieldExtended[] = useSelector(getMutableOrderFields);
    const mutableRequiredOrderFields: TOrderFieldExtended[] = useSelector(
        getMutableRequiredOrderFields
    );
    const mutableRequiredOrderFieldsWithoutOrderObject = useCreation(() => {
        return filter(mutableRequiredOrderFields, (field) => !field.isOrderObject);
    }, [mutableRequiredOrderFields]);

    const customerProfileId: number = useSelector(getOrderCustomerProfileId);
    const orderObjects: TOrderObjectsCreate[] = useSelector(getOrderObjects);

    const handleSelectOrderObject = async (id: number) => {
        try {
            dispatch(toggleLoading({ isLoading: true, ids: [LOADING_IDS.ORDER_OBJECTS] }));
            const orderObjectResp = await CRMAPIManager.request<OrderObjectResp>(async (api) => {
                return await api.getOrderObject(id, creds.crmID);
            });

            if (orderObjectResp.errorMessages) throw orderObjectResp.errorMessages;

            const orderObject = orderObjectResp.data.data;
            const modifiedOrderObjectData: TOrderFieldExtended = {
                crm_id: orderObject.crm_id,
                id: orderObject.id,
                is_photo: 0,
                name: ORDER_OBJECT,
                value: orderObject.name,
                isOrderObject: true,
            };

            const orderFieldsWithValuesFromOrderObject = map(mutableOrderFields, (field) => {
                const matchingField = find(orderObjectResp.data.data.order_fields, {
                    id: field.id,
                });
                return {
                    ...field,
                    value: matchingField ? matchingField.pivot.value || '' : '',
                };
            });

            const orderRequiredFieldsWithValuesFromOrderObject = map(
                mutableRequiredOrderFieldsWithoutOrderObject,
                (field) => {
                    const matchingField = find(orderObjectResp.data.data.order_fields, {
                        id: field.id,
                    });
                    return {
                        ...field,
                        value: matchingField ? matchingField.pivot.value || '' : '',
                    };
                }
            );

            dispatch(setOrderObject(orderObject));

            dispatch(
                setOrderProperty({
                    key: 'mutableOrderFields',
                    value: orderFieldsWithValuesFromOrderObject,
                })
            );
            dispatch(
                setOrderProperty({
                    key: 'mutableRequiredOrderFields',
                    value: hideOrderObject
                        ? orderRequiredFieldsWithValuesFromOrderObject
                        : [
                              modifiedOrderObjectData,
                              ...orderRequiredFieldsWithValuesFromOrderObject,
                          ],
                })
            );

            dispatch(toggleLoading({ isLoading: false, ids: [LOADING_IDS.ORDER_OBJECTS] }));
        } catch (err) {
            console.error(err);
            message.error('Ошибка при загрузке объекта заказа');
        }
    };

    async function fetchOrderObjects() {
        try {
            dispatch(toggleLoading({ isLoading: true, ids: [LOADING_IDS.ORDER_OBJECTS] }));
            const orderObjects = await CRMAPIManager.request<OrderObjectsResp>(async (api) => {
                return await api.getOrderObjects(creds.crmID, customerProfileId);
            });

            dispatch(toggleLoading({ isLoading: false, ids: [LOADING_IDS.ORDER_OBJECTS] }));
            if (orderObjects.errorMessages) throw orderObjects.errorMessages;

            dispatch(setOrderProperty({ key: 'orderObjects', value: orderObjects.data.data }));
        } catch (err) {
            console.error(err);
            message.error('Ошибка при загрузке объектов заказа');
        }
    }

    const handleSaveOrderObjectName = async () => {
        if (isOrderCreation) return;
        // if (selectedImmutableOrderObject.value === selectedMutableOrderObject.value) return;

        dispatch(toggleLoading({ isLoading: true, ids: [LOADING_IDS.ORDER_OBJECTS] }));
        try {
            // const orderObjectResp = await CRMAPIManager.request<OrderObjectResp>(async (api) => {
            //     return await api.updateOrderObject(
            //         creds.crmID,
            //         selectedMutableOrderObject.value,
            //         customerProfileId,
            //         selectedMutableOrderObject.id
            //     );
            // });

            // if (orderObjectResp.errorMessages) throw orderObjectResp.errorMessages;
            message.success('Название объекта заказа изменено');
        } catch (err) {
            message.error('Ошибка изменения названия объекта заказа');
        }
        dispatch(toggleLoading({ isLoading: false, ids: [LOADING_IDS.ORDER_OBJECTS] }));
    };

    const items: MenuProps['items'] = orderObjects?.map((item) => {
        return {
            label: (
                <div
                    key={item.id}
                    className="dropdown-item"
                    onClick={() => handleSelectOrderObject(item.id)}
                >
                    {item.name}
                </div>
            ),
            key: item.id,
        };
    });

    items.push({
        label: (
            <div className="btn-container">
                <Button onClick={() => navigate(`/lk/worker/crm/${creds?.crmID}/products/create`)} className="add-btn">
                    Добавить
                </Button>
            </div>
        ),
        key: 'add',
    });

    useEffect(() => {
        if (customerProfileId) fetchOrderObjects();
    }, [customerProfileId]);

    return (
        <div className="dropdown-container">
            <Input
                status={state.isTemplateExists ? 'error' : null}
                value={value}
                onChange={onChange}
                onBlur={handleSaveOrderObjectName}
            />
            <Dropdown menu={{ items }} placement="bottomRight" overlayStyle={{ width: '180px' }}>
                <Button className="dropdown-btn">
                    <div className="dropdown-btn-content">
                        <FontAwesomeIcon icon={faChevronDown} />
                    </div>
                </Button>
            </Dropdown>
        </div>
    );
};
