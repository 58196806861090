import { WorksheetResp } from '@api/responseModels/worksheet/worksheetsResponse';
import { CRMAPIManager } from '@classes/crmApiManager';

export const fetchWorksheets = async (crmID: number) => {
    const resp = await CRMAPIManager.request<WorksheetResp>(async (api) => {
        return await api.getWorksheets(crmID);
    });
    if (resp.errorMessages) throw resp.errorMessages;
    return resp.data.data;
};
