import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        data: null,
    },
    reducers: {
        setUserData: (state, action: PayloadAction<any>) => {
            state.data = action.payload;
        },
    },
});

export default usersSlice.reducer;

export const { setUserData } = usersSlice.actions;
