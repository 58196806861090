import { faCalendar, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Events = () => {
    return (
        <div className="events-wrapper">
            <div className="events-separator" />
            <div className="events-header">
                <p>События:</p>
                <div className="events-selector">
                    <div className="events-selector-button">
                        <FontAwesomeIcon icon={faChevronLeft} size="lg" color="brown" />
                    </div>
                    <p>
                        Сегодня <span>83</span>
                    </p>
                    <div className="events-selector-button">
                        <FontAwesomeIcon icon={faChevronRight} size="lg" color="brown" />
                    </div>
                </div>
                <div>
                    <FontAwesomeIcon icon={faCalendar} size="lg" color="brown" />
                </div>
            </div>

            <div className="event-categories">
                <div className="event-category" style={{ background: '#EFBD41' }}>
                    <p>
                        Работа: <span>2</span>
                    </p>
                </div>
                <div className="event-category" style={{ background: '#52A749' }}>
                    <p>
                        Обращения: <span>9</span>
                    </p>
                </div>
                <div className="event-category" style={{ background: '#B3B3B3' }}>
                    <p>
                        Личное: <span>48</span>
                    </p>
                </div>
            </div>

            <div className="event-list">
                <div className="event-list-header">
                    <p className="event-common">Общие:</p>
                    <div className="event-add">
                        <p>+ Событие</p>
                    </div>
                </div>

                {/* События */}
                <div className="event-item">
                    <p className="event-time">
                        13:00 <span>: удаление зуба</span>
                    </p>
                    <p className="event-company">Название компании</p>
                </div>
                <div className="event-item">
                    <p className="event-time">
                        13:00 <span>: удаление зуба</span>
                    </p>
                    <p className="event-company">Название компании</p>
                </div>
                <div className="event-item">
                    <p className="event-time">
                        13:00 <span>: удаление зуба</span>
                    </p>
                    <p className="event-company">Название компании</p>
                </div>
                <div className="event-item">
                    <p className="event-time">
                        13:00 <span>: удаление зуба</span>
                    </p>
                    <p className="event-company">Название компании</p>
                </div>
                <div className="event-item">
                    <p className="event-time">
                        13:00 <span>: удаление зуба</span>
                    </p>
                    <p className="event-company">Название компании</p>
                </div>
                <div className="event-item">
                    <p className="event-time">
                        13:00 <span>: удаление зуба</span>
                    </p>
                    <p className="event-company">Название компании</p>
                </div>
                <div className="event-item">
                    <p className="event-time">
                        13:00 <span>: удаление зуба</span>
                    </p>
                    <p className="event-company">Название компании</p>
                </div>
            </div>
        </div>
    );
};
