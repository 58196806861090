import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { Col, Divider, List, Row } from 'antd';
import { observer } from 'mobx-react';
import moment from 'moment';
import { TOrder } from 'types/Orders/order';
import { TMetadata } from 'types/metadata';

import { Loader } from '@atoms/loader';
import { SettingsManager } from '@classes/settingsManager';
import { GlobalConstants } from '@constants/global';
import {
    faCircleDot,
    faCircleMinus,
    faCirclePlus,
    faPlus,
    faTableList,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFetchDataList } from '@hooks/useFetchDataList';
import { OptionsMenu } from '@molecules/OptionsMenu/OptionsMenu';
import { HeaderListBreadcrumbs } from '@molecules/breadcrumbs/listsBreadcrumbs/HeaderListBreadcrumbs';
import { FunctionalMenu } from '@molecules/functionalMenu';
import { columnViewStore as vStore } from '@molecules/listViewChanger/listViewStore';
import { FilterStore } from '@organisms/ordersFilter/filterStore';
import { LastIdStore } from '@pages/lastIdStore';

import { debounce } from '../../../utils/functions';

import './style.scss';

const filterStore = new FilterStore();
const filter = filterStore.filter;

const OrderList = observer(() => {
    const [orderList, setOrderList] = useState<Array<TOrder>>([]);
    const [currentMeta, setCurrentMeta] = useState<TMetadata>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const navigate = useNavigate();
    const creds = SettingsManager.getConnectionCredentials();

    const { getDataList, isLoading: isOrderListLoading } = useFetchDataList({
        filter,
        profile: 'worker',
        setCurrentMeta,
        setDataList: setOrderList,
        getFnKey: 'getOrderList',
    });

    async function handleChangePage() {
        getDataList(currentPage + 1).then(() => {
            setCurrentPage((prev) => prev + 1);
        });
    }

    function handleCreateOrder() {
        LastIdStore.setLastOrderId(-1);
        navigate(`/lk/worker/crm/${creds.crmID}/orders/create`);
    }

    const handleSearchChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
        filterStore.changeQuery(e.target.value);
    }, GlobalConstants.SearchTimeout);

    useEffect(() => {
        getDataList(currentPage);
    }, []);

    return (
        <div id="app-order-list">
            {isOrderListLoading && <Loader />}
            <FunctionalMenu
                items={[
                    {
                        key: 'createOrder',
                        label: 'Создать заказ',
                        icon: <FontAwesomeIcon icon={faPlus} />,
                        onClick: handleCreateOrder,
                    },
                ]}
                dropdownItems={[]}
            />
            <HeaderListBreadcrumbs
                dataTotal={currentMeta?.total}
                title={'Заказы'}
                dataTitle={'Заказы'}
                dataIcon={faTableList}
                dataPrice={null}
                flag={false}
                isProduct={false}
                categoryPath={null}
                openCategoryCard={null}
                onSearch={handleSearchChange}
                searchPlaceHolder="По заказам"
            >
                <>
                    <OptionsMenu />
                </>
            </HeaderListBreadcrumbs>
            <InfiniteScroll
                dataLength={orderList.length}
                next={handleChangePage}
                hasMore={orderList.length < currentMeta?.total}
                loader={<Divider plain>Загрузка...</Divider>}
                endMessage={<Divider plain>Все заказы загружены :^)</Divider>}
            >
                <List
                    className="worker-card-list"
                    dataSource={orderList}
                    itemLayout="horizontal"
                    grid={{ column: vStore.col }}
                    renderItem={(item) => (
                        <List.Item className="worker-card">
                            <Row
                                gutter={[0, 16]}
                                onClick={() => {
                                    LastIdStore.setLastOrderId(item.id);
                                    navigate(`/lk/worker/crm/${creds.crmID}/orders/${item.id}`);
                                }}
                            >
                                <Col span={24} className="card-body">
                                    <Row justify={'space-between'} gutter={[0, 10]}>
                                        <Col>
                                            <span className="text-date">
                                                {moment(item.created_at)
                                                    .locale('ru')
                                                    .format('DD MMMM YYYY г. kk:mm, dd')}
                                            </span>
                                        </Col>
                                        <Col>
                                            <span
                                                className={`text-status ${
                                                    item?.finished_at ? 'text-blue' : 'text-green'
                                                }`}
                                            >
                                                {item?.order_status}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row gutter={[0, 16]}>
                                        <Col span={24}>
                                            <span className="text-name">
                                                Клиент: {item.customer_name}
                                            </span>
                                        </Col>
                                        <Col span={22}>{`Default comment`}</Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: 10,
                                        }}
                                    >
                                        <Col span={8}>
                                            <span className="icon-text-green">
                                                <FontAwesomeIcon icon={faCirclePlus} /> ~
                                            </span>
                                        </Col>
                                        <Col span={8}>
                                            <span className="icon-text-red">
                                                <FontAwesomeIcon icon={faCircleMinus} /> ~
                                            </span>
                                        </Col>
                                        <Col span={8}>
                                            <span className="icon-text-darkblue">
                                                <FontAwesomeIcon icon={faCircleDot} /> ~
                                            </span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </List.Item>
                    )}
                />
            </InfiniteScroll>
            {/* <Pagination
                current={currentPage}
                defaultCurrent={1}
                onChange={handleChangePage}
                pageSize={pStore.pS}
                showSizeChanger={false}
                total={currentMeta?.total ?? 1}
            /> */}
        </div>
    );
});

export { OrderList };
