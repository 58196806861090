import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useReactive } from 'ahooks';
import { Row } from 'antd';
import { map, unionBy } from 'lodash';
import { TOrder } from 'types/Orders/order';
import { TOrderObject } from 'types/orderObjects/orderObject';
import { TOrderFieldExtended } from 'types/worksheets/worksheetFields';

import { OrderResp } from '@api/responseModels/order/orderResponse';
import { OrderObjectResp } from '@api/responseModels/orderObjects/orderObjectResponse';
import { RequiredOrderFieldsResp } from '@api/responseModels/worksheet/requiredOrderFieldsResponse';
import { Loader } from '@atoms/loader';
import { CRMAPIManager } from '@classes/crmApiManager';
import { messageService } from '@classes/messageService';
import { SettingsManager } from '@classes/settingsManager';
import RenderIf from '@common/RenderIf';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionalMenu } from '@molecules/functionalMenu';
import { ProductTable, SwitchButtons, TotalSum } from '@molecules/orders/orderProfile';
import { useQuery } from '@tanstack/react-query';
import { getOrderObjectsLoading, getWorksheetsLoading } from '@utils/redux/Loadings/selectors';

import { fetchWorksheets } from './common/api';
import { EmptyRows, nameCellLarge, nameCellSmall } from './common/constants';
import { getModifiedData } from './common/helpers';
import { resetOrderState, setOrderData, setOrderProperty } from './common/redux/orderSlice';
import {
    getMutableOrderFields,
    getMutableRequiredOrderFields,
    getOrder,
    getSelectedOrderObjectFromOrder,
    getTotalProducts,
    getTotalServices,
} from './common/redux/selectors';
import { FieldsTable, ServiceTable, WorksheetButtons } from './components';
import { OrderCreateHeader } from './components/orderCreateHeader/orderCreateHeader';

import './orderProfile.scss'; 

const OrderCreate = () => {
    const state = useReactive({
        isDetailsActive: false,
        nameCellWidth: nameCellLarge,
        totalSum: 0,
        isLoading: false,
    });
    const creds = SettingsManager.getConnectionCredentials();

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const orderData: TOrder = useSelector(getOrder);
    const totalServices = useSelector(getTotalServices);
    const totalProducts = useSelector(getTotalProducts);
    const selectedOrderObject: TOrderObject = useSelector(getSelectedOrderObjectFromOrder);
    const isWorksheetsLoading = useSelector(getWorksheetsLoading);
    const isOrderObjectsLoading = useSelector(getOrderObjectsLoading);
    const mutableOrderFields: TOrderFieldExtended[] = useSelector(getMutableOrderFields);
    const mutableRequiredOrderFields: TOrderFieldExtended[] = useSelector(
        getMutableRequiredOrderFields
    );

    const { products: productsData = [], services: servicesData = [] } = useSelector(getOrder);

    const handleBtnClick = (obj: { type: string; details: boolean }) => {
        if (obj.type === 'details') {
            state.isDetailsActive = obj.details;
            localStorage.setItem('isDetailsActive', obj.details.toString());
        }
    };

    // Функция для уменьшения/увеличения ячейки с названием товара/услуги
    const onTableScroll = (e) => {
        // Тут проверяем больше ли scrollLeft размера ячейки с номером товара/услуги
        if (e.target.scrollLeft >= 35.95 && state.nameCellWidth !== nameCellSmall) {
            state.nameCellWidth = nameCellSmall;
        } else if (e.target.scrollLeft < 35.95 && state.nameCellWidth !== nameCellLarge) {
            state.nameCellWidth = nameCellLarge;
        }
    };

    const fetchAllRequiredFields = async () => {
        state.isLoading = true;
        try {
            const requiredOrderFields = await CRMAPIManager.request<RequiredOrderFieldsResp>(
                async (api) => {
                    return await api.getAllRequiredFields(creds?.crmID);
                }
            );

            if (requiredOrderFields.errorMessages) throw requiredOrderFields.errorMessages;

            const allRequiredFields: TOrderFieldExtended[] = map(
                requiredOrderFields.data.data,
                (field): TOrderFieldExtended => {
                    return {
                        ...field,
                        pivot: {
                            order_field_id: field.id,
                            worksheet_id: null,
                            is_shown: null,
                            is_used: null,
                            is_required: null,
                            is_saved: null,
                        },
                        value: '',
                    };
                }
            );

            dispatch(
                setOrderProperty({
                    key: 'immutableRequiredOrderFields',
                    value: allRequiredFields,
                })
            );
            dispatch(
                setOrderProperty({
                    key: 'mutableRequiredOrderFields',
                    value: allRequiredFields,
                })
            );
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        state.isLoading = false;
    };

    const handleCreateOrder = async () => {
        state.isLoading = true;
        try {
            const torder = await CRMAPIManager.request<OrderResp>(async (api) => {
                const products = productsData.filter(
                    (item) => item.id !== EmptyRows.EMPTY_ROW_1 && item.id !== EmptyRows.EMPTY_ROW_2
                );
                const services = servicesData.filter(
                    (item) => item.id !== EmptyRows.EMPTY_ROW_1 && item.id !== EmptyRows.EMPTY_ROW_2
                );
                return await api.saveOrder(
                    creds?.crmID,
                    products,
                    services,
                    orderData?.customer?.customer_profile?.id,
                    orderData?.start_planned_at,
                    selectedOrderObject?.id
                );
            });
            if (torder.errorMessages) throw torder.errorMessages;

            const orderId = torder.data.data.id;
            const orderObjectId = torder.data.data.order_object.id;
            const mergedOrderFields = unionBy(mutableOrderFields, mutableRequiredOrderFields, 'id');
            const fieldsDto = map(mergedOrderFields, (field) => {
                return {
                    order_field_id: field.pivot.order_field_id,
                    value: field.value,
                };
            });

            const syncFieldsResp = await CRMAPIManager.request<OrderResp>(async (api) => {
                return await api.syncOrderFieldsInOrders(orderId, creds.crmID, fieldsDto);
            });
            if (syncFieldsResp.errorMessages) throw syncFieldsResp.errorMessages;

            const syncFieldsOrderObjectResp = await CRMAPIManager.request<OrderObjectResp>(
                async (api) => {
                    return await api.syncOrderFieldsOrderObject(
                        creds.crmID,
                        orderObjectId,
                        fieldsDto
                    );
                }
            );
            if (syncFieldsOrderObjectResp.errorMessages)
                throw syncFieldsOrderObjectResp.errorMessages;

            messageService.sendSuccess('Заказ создан!');

            navigate(`/lk/worker/crm/${creds.crmID}/orders/${torder.data.data.id}`);
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        state.isLoading = false;
    };

    useEffect(() => {
        state.totalSum = totalServices + totalProducts;
    }, [totalServices, totalProducts]);

    useEffect(() => {
        fetchAllRequiredFields();
        const isDetailsActive = localStorage.getItem('isDetailsActive');
        if (isDetailsActive === 'true') state.isDetailsActive = true;

        dispatch(
            setOrderData({
                ...orderData,
                start_planned_at: new Date(),
                finish_planned_at: new Date(),
                products: getModifiedData([]),
                services: getModifiedData([]),
            })
        );

        return () => {
            dispatch(resetOrderState());
        };
    }, []);

    const { data: worksheets, isLoading: isLoadingWorksheets } = useQuery({
        queryKey: ['worksheets', creds.crmID],
        queryFn: () => fetchWorksheets(creds.crmID),
    });

    return (
        <div id="app-crm-profile" className="order-profile">
            <RenderIf
                condition={
                    state.isLoading ||
                    isWorksheetsLoading ||
                    isOrderObjectsLoading ||
                    isLoadingWorksheets
                }
            >
                <Loader />
            </RenderIf>
            <OrderCreateHeader hidePdfView openModalOnMount />
            <div className="buttons-wrapper">
                <WorksheetButtons data={worksheets} />
            </div>
            <FieldsTable isRequiredFields={true} hideOrderObject />
            <div className="buttons-wrapper">
                <SwitchButtons
                    orderData={orderData}
                    handleBtnClick={handleBtnClick}
                    isDetailsActive={state.isDetailsActive}
                    hideOrderSwitch
                />
            </div>

            <RenderIf condition={state.isDetailsActive}>
                <FieldsTable isRequiredFields={false} />
            </RenderIf>
            <Row onScroll={onTableScroll} className="table-container">
                <ServiceTable nameCellWidth={state.nameCellWidth} />
                <ProductTable nameCellWidth={state.nameCellWidth} />
            </Row>
            <TotalSum totalSum={state.totalSum} />

            <FunctionalMenu
                items={[
                    {
                        key: 'save',
                        label: 'Сохранить',
                        icon: <FontAwesomeIcon icon={faSave} />,
                        onClick: handleCreateOrder,
                    },
                ]}
                dropdownItems={[]}
                // selected={selectedProducts.length + selectedServices.length}
            />
        </div>
    );
};

export { OrderCreate };
