import { Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ColumnsType } from 'antd/lib/table';
import { TOrderFieldExtended } from 'types/worksheets/worksheetFields';

import { AddPhoto } from '@components/orders/addPhoto';

import { EditTemplate } from './components';

export const getColumns = ({
    worksheetName,
    onChange,
    onInputFocus,
    onTextareaBlur,
    focusedInputIndex,
}): ColumnsType<TOrderFieldExtended> => {
    return [
        {
            title: '№',
            dataIndex: 'id',
            width: '5%',
            key: 'id',
            render: (_text, _record, index) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                        }}
                    >
                        <span>{index + 1}</span>
                    </div>
                );
            },
        },
        {
            title: `Поля из ${worksheetName}`,
            dataIndex: 'name',
            key: 'name',
            width: '47.5%',
        },
        {
            title: 'Описание полей',
            dataIndex: 'value',
            key: 'value',
            width: '47.5%',
            render: (value, _, index) => {
                return (
                    <TextArea
                        placeholder="Введите значение"
                        className={'required-field custom-fields-textarea'}
                        value={value}
                        onChange={(e) => onChange(e.target.value, index)}
                        onBlur={onTextareaBlur}
                        autoSize={focusedInputIndex === index}
                        onFocus={() => onInputFocus(index)}
                    />
                );

                // if (record.isPhotoField) return <AddPhoto id={record.key} />;

                // return undefined;
            },
        },
    ];
};

export const getColumnsReq = ({
    onChange,
    hideOrderObject,
    onInputFocus,
    onTextareaBlur,
    focusedInputIndex,
}): ColumnsType<TOrderFieldExtended> => {
    return [
        {
            title: '№',
            dataIndex: 'id',
            width: '5%',
            key: 'id',
            render: (_text, _record, index) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                        }}
                    >
                        <span>{index + 1}</span>
                    </div>
                );
            },
        },
        {
            title: `Обязательные поля (все)`,
            dataIndex: 'name',
            key: 'name',
            width: '47.5%',
        },
        {
            title: 'Описание полей',
            dataIndex: 'value',
            key: 'value',
            width: '47.5%',
            render: (value, _, index) => {
                // return index === 0 && !hideOrderObject ? (
                //     <EditTemplate value={value} onChange={(e) => onChange(e.target.value, index)} />
                // ) : (
                   return ( <TextArea
                        placeholder="Введите значение"
                        className={'required-field custom-fields-textarea'}
                        value={value}
                        onChange={(e) => onChange(e.target.value, index)}
                        onBlur={onTextareaBlur}
                        autoSize={focusedInputIndex === index}
                        onFocus={() => onInputFocus(index)}
                    />)
                // );
            },
        },
    ];
};
