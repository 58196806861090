import { useState } from 'react';
import { useSelector } from 'react-redux';

import { Col, Row } from 'antd';

import { faTelegram, faVk, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faChevronDown, faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ImgUploader } from '@organisms/imgUploader/imgUploader';

import { getUserData } from '../common/redux/selectors';

import '../userProfile.scss';

export const UserInfo = () => {
    const [isShowInfo, setIsShowInfo] = useState(false);
    const userData = useSelector(getUserData);

    return (
        <>
            <Row className="upper-info user-info" justify={'center'}>
                <Col className="avatar outer-box-for-image">
                    <ImgUploader
                        photoSrc={userData?.picture as string}
                        onImageSelect={() => {}}
                        isEdit={false}
                        onImageDelete={() => {}}
                        imgHeight={150}
                        imgWidth={125}
                        imgStyle={{ maxWidth: '100%' }}
                    />
                </Col>
                <div className="user-info-right-side">
                    <div className="user-info-name">
                        <div>
                            <p>
                                <span>Фам: </span>
                                {userData?.surname}
                            </p>
                            <p>
                                <span>Имя: </span>
                                {userData?.name}
                            </p>
                            <p>
                                <span>Отч: </span>
                                {userData?.patronymic}
                            </p>
                        </div>
                        {/* Доделать */}
                        <div className="my-info-button" onClick={() => setIsShowInfo(!isShowInfo)}>
                            <p>Моя информация</p>{' '}
                            <FontAwesomeIcon
                                className={`${isShowInfo ? 'arrow-active' : 'arrow-inactive'}`}
                                icon={faChevronDown}
                            />
                        </div>
                        {/*  */}
                    </div>

                    <div className="contact-info">
                        <div className="user-rating">
                            <div className="user-rating-star">
                                <FontAwesomeIcon icon={faStar} color="yellow" size="2x" />
                                <span>4.7</span>
                            </div>
                            <p>
                                Отзывы: <span>7</span>
                            </p>
                        </div>

                        <div className="user-contacts" onClick={() => {}}>
                            <div style={{ background: '#25D366' }}>
                                <FontAwesomeIcon icon={faWhatsapp} color="white" size="sm" />
                            </div>
                            <div style={{ background: '#4680C2' }}>
                                <FontAwesomeIcon icon={faVk} color="white" size="sm" />
                            </div>
                            <div style={{ background: '#30A3E6' }}>
                                <FontAwesomeIcon icon={faTelegram} color="white" size="sm" />
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </>
    );
};
