import { createSelector } from '@reduxjs/toolkit';

export const getOrdersProfile = (state) => state.orders.ordersProfile;

export const getOrder = createSelector(getOrdersProfile, (profile) => profile.order.data);

export const getSelectedProducts = createSelector(
    getOrdersProfile,
    (profile) => profile.order.selectedProducts
);

export const getTotalProducts = createSelector(
    getOrdersProfile,
    (profile) => profile.order.totalProducts
);

export const getSelectedServices = createSelector(
    getOrdersProfile,
    (profile) => profile.order.selectedServices
);

export const getTotalServices = createSelector(
    getOrdersProfile,
    (profile) => profile.order.totalServices
);

export const getSelectedWorksheet = createSelector(
    getOrdersProfile,
    (profile) => profile.order.selectedWorksheet
);

export const getImmutableOrderData = createSelector(
    getOrdersProfile,
    (profile) => profile.order.immutableOrderData
);

export const getOrderObjects = createSelector(
    getOrdersProfile,
    (profile) => profile.order.orderObjects
);

export const getMutableOrderFields = createSelector(
    getOrdersProfile,
    (profile) => profile.order.mutableOrderFields
);

export const getImmutableOrderFields = createSelector(
    getOrdersProfile,
    (profile) => profile.order.immutableOrderFields
);

export const getMutableRequiredOrderFields = createSelector(
    getOrdersProfile,
    (profile) => profile.order.mutableRequiredOrderFields
);

export const getImmutableRequiredOrderFields = createSelector(
    getOrdersProfile,
    (profile) => profile.order.immutableRequiredOrderFields
);

export const getOrderCustomerProfileId = createSelector(
    getOrder,
    (order) => order?.customer?.customer_profile?.id
);

export const getOrderFields = createSelector(getOrder, (order) => order?.order_fields);

export const getAllRequiredOrderFields = createSelector(
    getOrder,
    (order) => order?.all_required_order_fields
);

export const getSelectedOrderObjectFromOrder = createSelector(
    getOrder,
    (order) => order?.order_object
);

// Временно
export const getSelectedItemsWithoutExecutor = createSelector(
    getOrdersProfile,
    (profile) => profile.order.selectedItemsWithoutExecutor
);

export const getTotalItemsWithoutExecutor = createSelector(
    getOrdersProfile,
    (profile) => profile.order.totalItemsWithoutExecutor
);
