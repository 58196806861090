import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useMount } from 'ahooks';

import { UserResp } from '@api/responseModels/user/userResponse';
import { Loader } from '@atoms/loader';
import { CRMAPIManager } from '@classes/crmApiManager';
import { messageService } from '@classes/messageService';
import { SettingsManager } from '@classes/settingsManager';

import { Events } from './blocks/events';
import { Header } from './blocks/header';
import { UserInfo } from './blocks/userInfo';
import { setUserData } from './common/redux/usersSlice';

import './userProfile.scss';

export const UserProfile = () => {
    const { workerID: userId } = useParams();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const dispatch = useDispatch();

    async function getUser() {
        setIsLoading(true);
        try {
            const creds = SettingsManager.getConnectionCredentials();
            const tuser = await CRMAPIManager.request<UserResp>(async (api) => {
                return await api.getUser(+userId, creds.crmID);
            });
            if (tuser.errorMessages) throw tuser.errorMessages;
            dispatch(setUserData(tuser.data.data));
        } catch (errors) {
            messageService.sendErrorList(errors);
        }
        setIsLoading(false);
    }

    useMount(() => getUser());

    return (
        <div id="app-worker-profile" className="user-profile-wrapper">
            {isLoading && <Loader />}
            <Header />
            <UserInfo />
            <Events />
        </div>
    );
};
