import ImageGallery from 'react-image-gallery';

import { ImageGalleryProps } from './common/types';

import './customImageGallery.scss';

export const CustomImageGallery: React.FC<ImageGalleryProps> = (props) => {
    return (
        <div className="custom-image-gallery">
            <ImageGallery {...props} />
        </div>
    );
};
