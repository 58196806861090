import { Input, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';

import { Characteristics } from './common/types';

import style from './characteristicsTable.module.scss';

interface CharacteristicsTableProps {
    data: Characteristics[];
    onChange: (id: number, value: string) => void;
    isEditable?: boolean;
}

export const CharacteristicsTable: React.FC<CharacteristicsTableProps> = ({
    data,
    onChange,
    isEditable,
}) => {
    const columns: ColumnsType<Characteristics> = [
        {
            width: '50%',
            dataIndex: 'name',
            key: 'name',
        },
        {
            width: '50%',
            align: 'center',
            dataIndex: 'value',
            key: 'age',
            render: (_, record) => {
                return isEditable ? (
                    <span className={style['custom-input-wrapper']}>
                        <Input
                            className={style['custom-input']}
                            type="text"
                            value={record.value}
                            onChange={(e) => onChange(record.id, e.target.value)}
                        />
                    </span>
                ) : (
                    record.value
                );
            },
        },
    ];

    return (
        <Table
            dataSource={data}
            columns={columns}
            pagination={false}
            bordered
            style={{ width: '100%' }}
            showHeader={false}
        />
    );
};
