import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useReactive } from 'ahooks';
import { Breadcrumb, message } from 'antd';
import { Col, Row } from 'antd';
import { CarouselRef } from 'antd/lib/carousel';
import { isEmpty, isEqual, map, unionBy } from 'lodash';
import { observer } from 'mobx-react';
import { TOrder } from 'types/Orders/order';
import { TOrderFieldExtended } from 'types/worksheets/worksheetFields';
import { TWorksheet } from 'types/worksheets/worksheets';

import { OrderResp } from '@api/responseModels/order/orderResponse';
import { OrderObjectResp } from '@api/responseModels/orderObjects/orderObjectResponse';
import { Loader } from '@atoms/loader';
import { CRMAPIManager } from '@classes/crmApiManager';
import { messageService } from '@classes/messageService';
import { SettingsManager } from '@classes/settingsManager';
import RenderIf from '@common/RenderIf';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionalMenu } from '@molecules/functionalMenu';
import { OrderStatus, OrderWorkers, SwitchButtons, TotalSum } from '@molecules/orders/orderProfile';
import { LastIdStore } from '@pages/lastIdStore';
import { useQuery } from '@tanstack/react-query';
import { ERROR_IDS } from '@utils/redux/Errors/errorIds';
import { pushError, removeError } from '@utils/redux/Errors/errorSlice';
import { getOrderError } from '@utils/redux/Errors/selectors';
import { LOADING_IDS } from '@utils/redux/Loadings/loadingIds';
import { toggleLoading } from '@utils/redux/Loadings/loadingSlice';
import {
    getOrderLoading,
    getOrderObjectsLoading,
    getWorksheetsLoading,
} from '@utils/redux/Loadings/selectors';

import { fetchWorksheets } from './common/api';
import { nameCellLarge, nameCellSmall, withoutExecutorFields } from './common/constants';
import { getInitialMenuItems, getMenuItemsForItemsWithoutExecutor } from './common/data';
import { getModifiedData } from './common/helpers';
import { setModifiedOrderData } from './common/helpers/setModifiedOrderData';
import {
    clearSelectedItemsWithoutExecutor,
    clearSelectedProducts,
    clearSelectedServices,
    deleteSelectedProducts,
    deleteSelectedServices,
    resetOrderState,
    setOrderData,
    setOrderProperty,
} from './common/redux/orderSlice';
import {
    getImmutableOrderData,
    getImmutableOrderFields,
    getImmutableRequiredOrderFields,
    getMutableOrderFields,
    getMutableRequiredOrderFields,
    getOrder,
    getSelectedItemsWithoutExecutor,
    getSelectedProducts,
    getSelectedServices,
    getSelectedWorksheet,
    getTotalProducts,
    getTotalServices,
} from './common/redux/selectors';
import { EmptyRows, State } from './common/types';
import {
    FieldsTable,
    OrderComment,
    ProductTable,
    ServiceTable,
    WorksheetButtons,
} from './components';
import { OrderCreateHeader } from './components/orderCreateHeader/orderCreateHeader';
import { TOrderTask } from './components/taskTable/common/types';
import { TaskTable } from './components/taskTable/taskTable';
import { WithoutExecutorTable } from './components/withoutExecutorTable/withoutExecutorTable';

import './orderProfile.scss';

const OrderProfile = observer(() => {
    const dispatch = useDispatch();

    const orderData: TOrder = useSelector(getOrder);
    const immutableOrderData: TOrder = useSelector(getImmutableOrderData);
    const { products: productsData = [], services: servicesData = [] } =
        useSelector(getOrder) || {};
    const selectedServices = useSelector(getSelectedServices);
    const totalServices = useSelector(getTotalServices);
    const selectedProducts = useSelector(getSelectedProducts);
    const totalProducts = useSelector(getTotalProducts);
    const selectedWorksheet: TWorksheet = useSelector(getSelectedWorksheet);
    const immutableOrderFields: TOrderFieldExtended[] = useSelector(getImmutableOrderFields);
    const mutableOrderFields: TOrderFieldExtended[] = useSelector(getMutableOrderFields);
    const mutableRequiredOrderFields: TOrderFieldExtended[] = useSelector(
        getMutableRequiredOrderFields
    );
    const immutableRequiredOrderFields: TOrderFieldExtended[] = useSelector(
        getImmutableRequiredOrderFields
    );

    const isProfileLoading = useSelector(getOrderLoading);
    const isWorksheetsLoading = useSelector(getWorksheetsLoading);
    const isOrderObjectsLoading = useSelector(getOrderObjectsLoading);
    const orderError = useSelector(getOrderError);

    const selectedItemsWithoutExecutor = useSelector(getSelectedItemsWithoutExecutor);

    const state = useReactive<State>({
        isDetailsActive: false,
        totalSum: 0,
        isModalOpen: false,
        isSaveButtonActive: false,
        nameCellWidth: nameCellLarge,
        tasks: [],
        selectedTasks: [],
        totalTasks: 0,
    });
    const [initialMenuItemsData, setInitialMenuItemsData] = useState([]);

    const navigate = useNavigate();
    const params = useParams();
    const creds = SettingsManager.getConnectionCredentials();
    const sliderStatusRef = useRef<CarouselRef>(null);

    async function getOrders() {
        dispatch(toggleLoading({ isLoading: true, ids: [LOADING_IDS.ORDER] }));
        dispatch(removeError(ERROR_IDS.ORDER));

        try {
            const torder = await CRMAPIManager.request<OrderResp>(async (api) => {
                return await api.getOrder(+params?.orderID, creds.crmID);
            });

            if (torder.errorMessages) throw torder.errorMessages;

            setModifiedOrderData(torder.data, dispatch);
        } catch (err) {
            dispatch(pushError({ errorId: ERROR_IDS.ORDER, err }));
        }
        dispatch(toggleLoading({ isLoading: false, ids: [LOADING_IDS.ORDER] }));
    }

    const saveOrder = async () => {
        dispatch(toggleLoading({ isLoading: true, ids: [LOADING_IDS.ORDER] }));
        dispatch(removeError(ERROR_IDS.ORDER));
        try {
            const torder = await CRMAPIManager.request<OrderResp>(async (api) => {
                const profileId = orderData.customer.customer_profile.id;
                const products = productsData.filter(
                    (item) => item.id !== EmptyRows.EMPTY_ROW_1 && item.id !== EmptyRows.EMPTY_ROW_2
                );
                const services = servicesData.filter(
                    (item) => item.id !== EmptyRows.EMPTY_ROW_1 && item.id !== EmptyRows.EMPTY_ROW_2
                );
                return await api.updateOrder(
                    creds?.crmID,
                    orderData?.id,
                    products,
                    services,
                    profileId,
                    'customer',
                    orderData?.start_planned_at,
                    orderData?.order_object?.id
                );
            });
            if (torder.errorMessages) throw torder.errorMessages;
            dispatch(
                setOrderData({
                    ...torder.data.data,
                    products: getModifiedData(torder.data.data.products),
                    services: getModifiedData(torder.data.data.services),
                    // Временно
                    withoutExecutorFields: withoutExecutorFields,
                })
            );

            dispatch(
                setOrderProperty({
                    key: 'immutableOrderData',
                    value: {
                        ...torder.data.data,
                        products: getModifiedData(torder.data.data.products),
                        services: getModifiedData(torder.data.data.services),
                        // Временно
                        withoutExecutorFields: withoutExecutorFields,
                    },
                })
            );
            if (torder.statusCode === 200) messageService.sendSuccess('Сохранено!');
        } catch (err) {
            dispatch(pushError({ errorId: ERROR_IDS.ORDER, err }));
        }
        dispatch(toggleLoading({ isLoading: false, ids: [LOADING_IDS.ORDER] }));
    };

    const saveOrderFields = async () => {
        dispatch(toggleLoading({ isLoading: true, ids: [LOADING_IDS.ORDER] }));
        dispatch(removeError(ERROR_IDS.ORDER));

        try {
            const mergedOrderFields = unionBy(mutableOrderFields, mutableRequiredOrderFields, 'id');
            const dto = map(mergedOrderFields, (field) => {
                return {
                    order_field_id: field.pivot.order_field_id,
                    value: field.value,
                };
            });

            const syncFieldsOrderObjectResp = await CRMAPIManager.request<OrderObjectResp>(
                async (api) => {
                    return await api.syncOrderFieldsOrderObject(
                        creds.crmID,
                        orderData.order_object.id,
                        dto
                    );
                }
            );
            if (syncFieldsOrderObjectResp.errorMessages)
                throw syncFieldsOrderObjectResp.errorMessages;

            const torder = await CRMAPIManager.request<OrderResp>(async (api) => {
                return await api.syncOrderFieldsInOrders(+params?.orderID, creds.crmID, dto);
            });

            if (torder.errorMessages) throw torder.errorMessages;
            setModifiedOrderData(torder.data, dispatch);

            if (torder.statusCode === 200) message.success('Поля обновлены!');
        } catch (err) {
            dispatch(pushError({ errorId: ERROR_IDS.ORDER, err }));
        }
        dispatch(toggleLoading({ isLoading: false, ids: [LOADING_IDS.ORDER] }));
    };

    const handleBtnClick = (obj: { type: string; details: boolean }) => {
        if (obj.type === 'details') {
            state.isDetailsActive = obj.details;
            localStorage.setItem('isDetailsActive', obj.details.toString());
        }
    };

    const handleSwitchOrder = async (id) => {
        LastIdStore.setLastOrderId(+id);
        await navigate(`/lk/worker/crm/${creds.crmID}/orders/${id}`);
    };

    const handleSave = async () => {
        if (!isEqual(orderData, immutableOrderData)) await saveOrder();

        if (
            !isEqual(mutableOrderFields, immutableOrderFields) ||
            !isEqual(immutableRequiredOrderFields, mutableRequiredOrderFields)
        )
            await saveOrderFields();
    };

    const handleDeleteSelectedItems = () => {
        if (!isEmpty(selectedProducts)) {
            dispatch(deleteSelectedProducts());
        }

        if (!isEmpty(selectedServices)) {
            dispatch(deleteSelectedServices());
        }

        if (!isEmpty(state.selectedTasks)) {
            let tasksCopy = state.tasks.filter(
                (t) => state.selectedTasks.find((st) => st == t.id) == undefined
            );
            state.tasks = tasksCopy;
            state.selectedTasks = [];
            let newTotal = 0;
            tasksCopy.forEach((tc) => (newTotal += tc.total));
            state.totalTasks = newTotal;
        }
    };

    const handleDeselectAllItems = () => {
        dispatch(clearSelectedProducts());
        dispatch(clearSelectedServices());
        dispatch(clearSelectedItemsWithoutExecutor());
        state.selectedTasks = [];
    };

    // Функция для уменьшения/увеличения ячейки с названием товара/услуги
    const onTableScroll = (e) => {
        // Тут проверяем больше ли scrollLeft размера ячейки с номером товара/услуги
        if (e.target.scrollLeft >= 35.95 && state.nameCellWidth !== nameCellSmall) {
            state.nameCellWidth = nameCellSmall;
        } else if (e.target.scrollLeft < 35.95 && state.nameCellWidth !== nameCellLarge) {
            state.nameCellWidth = nameCellLarge;
        }
    };

    const { data: worksheets, isLoading: isLoadingWorksheets } = useQuery({
        queryKey: ['worksheets', creds.crmID],
        queryFn: () => fetchWorksheets(creds.crmID),
    });

    useEffect(() => {
        state.isSaveButtonActive =
            !isEqual(orderData, immutableOrderData) ||
            !isEqual(mutableOrderFields, immutableOrderFields) ||
            !isEqual(mutableRequiredOrderFields, immutableRequiredOrderFields);
    }, [orderData, mutableOrderFields, mutableRequiredOrderFields]);

    useEffect(() => {
        const menuData = !isEmpty(selectedItemsWithoutExecutor)
            ? getMenuItemsForItemsWithoutExecutor({ handleDeselectAllItems })
            : getInitialMenuItems({
                  handleDeleteSelectedItems,
                  handleSaveOrder: handleSave,
                  handleDeselectAllItems,
              });

        setInitialMenuItemsData(menuData);
    }, [selectedServices, selectedProducts, selectedItemsWithoutExecutor]);

    useEffect(() => {
        getOrders();
    }, [params.orderID]);

    useEffect(() => {
        state.totalSum = totalServices + totalProducts + state.totalTasks;
    }, [totalServices, totalProducts, state.totalTasks]);

    useEffect(() => {
        if (orderError) messageService.sendErrorList(orderError.data);
    }, [orderError]);

    useEffect(() => {
        const isDetailsActive = localStorage.getItem('isDetailsActive');
        if (isDetailsActive === 'true') state.isDetailsActive = true;

        return () => {
            dispatch(resetOrderState());
        };
    }, []);

    return (
        <>
            <div id="app-crm-profile" className="order-profile">
                <OrderCreateHeader />
                <Row className="breadcrumbs-controls">
                    <Col>
                        <Breadcrumb>
                            <Breadcrumb.Item
                                onClick={() => navigate(`/lk/worker/crm/${creds?.crmID}/orders`)}
                            >
                                <span className="order-crumb-name">Заказы</span>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{`${selectedWorksheet?.name} № ${params.orderID}`}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                </Row>
                <div className="buttons-wrapper">
                    <WorksheetButtons data={worksheets} />
                </div>
                <RenderIf condition={!isEmpty(immutableRequiredOrderFields)}>
                    <FieldsTable isRequiredFields />
                </RenderIf>
                <div className="buttons-wrapper">
                    <SwitchButtons
                        handleSwitchOrder={handleSwitchOrder}
                        orderData={orderData}
                        handleBtnClick={handleBtnClick}
                        isDetailsActive={state.isDetailsActive}
                    />
                </div>
                <RenderIf condition={state.isDetailsActive}>
                    <FieldsTable isRequiredFields={false} />
                </RenderIf>
                <Row onScroll={onTableScroll} className="table-container">
                    <WithoutExecutorTable nameCellWidth={state.nameCellWidth} />
                    <ServiceTable nameCellWidth={state.nameCellWidth} />
                    <TaskTable
                        nameCellWidth={state.nameCellWidth}
                        tasks={state.tasks}
                        setTasks={(value: TOrderTask[]) => (state.tasks = value)}
                        selectedTasks={state.selectedTasks}
                        setSelectedTasks={(value: number[]) => (state.selectedTasks = value)}
                        totalTasks={state.totalTasks}
                        setTotalTasks={(value: number) => (state.totalTasks = value)}
                    />
                    <ProductTable nameCellWidth={state.nameCellWidth} />
                </Row>
                <TotalSum totalSum={state.totalSum} />
                <OrderComment />
                <OrderStatus sliderStatusRef={sliderStatusRef} />
                <OrderWorkers />
            </div>

            <RenderIf
                condition={
                    isProfileLoading ||
                    isWorksheetsLoading ||
                    isOrderObjectsLoading ||
                    isLoadingWorksheets
                }
            >
                <Loader />
            </RenderIf>
            <RenderIf
                condition={
                    !isEmpty(selectedProducts) ||
                    !isEmpty(selectedServices) ||
                    !isEmpty(state.selectedTasks) ||
                    !isEmpty(selectedItemsWithoutExecutor) ||
                    state.isSaveButtonActive
                }
            >
                <FunctionalMenu
                    items={
                        state.isSaveButtonActive
                            ? [
                                  {
                                      key: 'save',
                                      label: 'Сохранить',
                                      icon: <FontAwesomeIcon icon={faSave} />,
                                      onClick: handleSave,
                                  },
                              ]
                            : initialMenuItemsData.filter((mi) => mi.key != 'delete')
                    }
                    dropdownItems={
                        selectedProducts.length +
                            selectedServices.length +
                            state.selectedTasks.length >
                            0 || !isEmpty(selectedItemsWithoutExecutor)
                            ? initialMenuItemsData.filter((mi) => mi.key == 'delete')
                            : []
                    }
                    selected={
                        !isEmpty(selectedItemsWithoutExecutor)
                            ? selectedItemsWithoutExecutor.length
                            : selectedProducts.length +
                              selectedServices.length +
                              state.selectedTasks.length
                    }
                />
            </RenderIf>
        </>
    );
});

export { OrderProfile };
