import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'antd';
import { includes } from 'lodash';

import RenderIf from '@common/RenderIf';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { setSelectedFiles } from '../../redux/filesViewerSlice';
import { getSelectedFiles } from '../../redux/selectors';

import './thumbnail.scss';

interface ThumbnailProps {
    imageSource?: string;
    icon?: any;
    alt?: string;
    label: string;
    id: number;
}

export const Thumbnail = ({ imageSource, alt, label, icon, id }: ThumbnailProps) => {
    console.log("🚀 ~ Thumbnail ~ imageSource:", imageSource)
    const dispatch = useDispatch();
    const selectedFiles = useSelector(getSelectedFiles);

    const handleSelect = (e, id) => {
        e.stopPropagation();
        dispatch(setSelectedFiles(id));
    };

    return (
        <>
            <div className="thumbnail-box">
                <RenderIf condition={imageSource}>
                    <img src={imageSource} alt={alt} className="thumbnail-image-custom" />
                </RenderIf>

                <RenderIf condition={icon}>
                    <div className="thumbnail-icon-custom">{icon}</div>
                </RenderIf>

                <Button
                    onClick={(e) => handleSelect(e, id)}
                    type={includes(selectedFiles, id) ? 'primary' : 'default'}
                    className="thumbnail-checkbox"
                >
                    <FontAwesomeIcon icon={faCheck} />
                </Button>
            </div>
            <p className="custom-thumbnail-title">{label}</p>
        </>
    );
};
