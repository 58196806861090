import { filter, find, map } from 'lodash';
import { TOrderFieldExtended } from 'types/worksheets/worksheetFields';

import { OrderResp } from '@api/responseModels/order/orderResponse';

import { withoutExecutorFields } from '../constants';
import { setOrderData, setOrderProperty } from '../redux/orderSlice';
import { getModifiedData } from './getModifiedData';

export const setModifiedOrderData = (torder: OrderResp, dispatch: (action: any) => void) => {
    const allRequiredFields: TOrderFieldExtended[] = map(
        torder.data.all_required_empty_order_fields,
        (field): TOrderFieldExtended => {
            return {
                ...field,
                pivot: {
                    order_field_id: field.id,
                    worksheet_id: null,
                    is_shown: null,
                    is_used: null,
                    is_required: null,
                    is_saved: null,
                },
                value: '',
            };
        }
    );

    const allRequiredFieldsWithoutDuplicates = filter(allRequiredFields, (reqField) => {
        return !find(
            torder.data.order_fields,
            (field) => field.id === reqField.id && field.pivot.value !== null
        );
    });

    dispatch(
        setOrderProperty({
            key: 'immutableRequiredOrderFields',
            value: allRequiredFieldsWithoutDuplicates,
        })
    );
    dispatch(
        setOrderProperty({
            key: 'mutableRequiredOrderFields',
            value: allRequiredFieldsWithoutDuplicates,
        })
    );

    dispatch(
        setOrderData({
            ...torder.data,
            products: getModifiedData(torder.data.products),
            services: getModifiedData(torder.data.services),
            // Временно
            withoutExecutorFields: withoutExecutorFields,
        })
    );

    dispatch(
        setOrderProperty({
            key: 'immutableOrderData',
            value: {
                ...torder.data,
                products: getModifiedData(torder.data.products),
                services: getModifiedData(torder.data.services),
                // Временно
                withoutExecutorFields: withoutExecutorFields,
            },
        })
    );
};
